@import "../../theme.scss";
@import "../../mixins.scss";

.Header {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    min-height: 4em;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    z-index: 100;
    padding: 0;
    // color: #272727;
    // background: #fdfdfd;
    color: #fdfdfd;
    background: #3c3e43;
    border-bottom: 1px solid $color-active;
}

.Aside {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
}

.Logo {
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items:center;
    margin: 0 $spacing/2;
    padding: 0 $spacing 10px $spacing;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
}

.LogoImage {
    height: 35px;
    flex: 1;
}

.VR {
    width: 2px;
    // background-color: $color-border;
    background-color: #fdfdfd;
    align-self: stretch;
    margin: $spacing 0;
}

.Centered {
    margin: 0 $spacing / 2;
    padding: 0 $spacing;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
}

.Link {
    margin: 0 $spacing / 2;
    padding: 0 $spacing;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: #FFF;
    font-size: 17px;
    border-bottom: 5px solid transparent;
    transition: $transition-fast;

    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: #FFF;
        border-color: rgb(70, 78, 86);
    }

    &Active,
    &Active:hover,
    &Active:visited {
        border-color: $color-active;
    }
}

.BurgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 33%;
}

.BurgerToggle {
    @include reset-appearance;
    margin-left: $spacing;
    font-size: 1.5em;
    margin-top: -4px;
    width: 2em;
    height: 2em;
    text-align: center;
    cursor: pointer;
    border-radius: $border-radius;

    &:hover {
        background-color: #777;
    }
}

.BurgerContent {
    position: fixed;
    left: 0;
    top: 64px;
    min-width: 230px;
    max-width: 100vw;
    background-color: #3c3e43;
    z-index: 1000;
    padding-bottom: $spacing;
    @include box-shadow;

    .Link,
    .Centered {
        padding-bottom: $spacing/4;
        margin-top: $spacing;
    }
}
