@import "../theme.scss";

.Placeholder {
    min-height: 200px;
}
.PlaceholderIcon {
    width: 3em;
    height: 3em;
    display: inline-flex;
    margin-right: $spacing / 2;
}

.DateFilterCol {
    text-align: right;
}

.VisPickerInline {
    display: inline-block;
}

.HeadingWithAside {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $spacing;
}

.CircleIcon {
    background-color: $color-box;
    vertical-align: middle;
    border-radius: 50% 50%;
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    :global(img) {
        height: 2.5rem;
    }
}

.Clear {
    margin: -$spacing 0 $spacing 4rem;
    color: $color-text-muted;
}


// .App {
//     height: 100vh;
//     box-sizing: border-box;
//   }
  
//   .PanelContent {
//     text-align: center;
//   }
  