.Footer {
    font-size: 15px;
    line-height: 24px;
    background: #3c3e43;
    color: #fff;
    text-align: center;

    &Link {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:visited,
        &:active,
        &:focus {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.FooterLinks {
    padding: 20px;

    &Header {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 1.5rem 0;
    }
}

.Social {
    display: inline-block;
    border-top: 1px solid rgba(109, 118, 128, 0.5);
    margin: 0 auto;
    padding: 1.5rem 0 0;

    .FooterLink {
        margin-left: 10px;
        margin-right: 10px;
    }

    img {
        height: 1.875rem;
        margin-right: 0.6rem;
        vertical-align: middle;
    }
}

.Copyright {
    padding: 1rem 20px;
    color: rgba(255, 255, 255, 0.8);
    background-color: #3c3e43;
}
