@import "../theme.scss";

.Page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // min-height: 100vh;
    height: 100vh;
}

.Main {
    flex: 1 0 auto;
    // padding: $spacing * 2 + 56px $spacing $spacing * 2 $spacing;
    // max-width: 1750px;
    // width: calc(100% - (#{$spacing} * 2));
    // margin: 0 auto;

    width: 100%;
    padding: $spacing * 2 + 40px 0 0 0;
}

.VerticalCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.Inverse {
    background-color: $color-paper-inverse;
    color: $color-text-inverse;
}

.IframeRow {
    border: none;
    width: 100%;
    height: 100%;
}